<template>
  <div class="imgBox">
    <div class="rowHead">
      <div class="rowHeadLeft">
        <el-tabs v-model="activeName" @tab-click="handleTabClick">
          <el-tab-pane label="做单截图" name="a"></el-tab-pane>
       <!-- <el-tab-pane label="常见问题" name="b"></el-tab-pane>-->
        </el-tabs>
      </div>
      <div class="rowHeadRight">
        <div class="colHeadItem colHeadItemDesc">
          共助力{{ total }}刀，可左右滑动查看
        </div>
      </div>
	  <div class="colHeadItem">
          <el-button
            @click="shuaxin"
            size="small"
            icon="el-icon-refresh-left"
            type="primary"
            >刷新</el-button
          >
        </div>
    </div>

    <div class="middleBox" ref="box">
      <div v-if="!listData.length">
        <el-empty description="当前订单无照片,请等一会再来看看哦！"></el-empty>
      </div>
      <el-row v-else type="flex" class="rowBox" :gutter="2">
        <el-col v-for="(item, i) in listData" :key="i" :xs="12" :sm="4">
          <div class="mItemBox">
            <div class="swiperBox">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <img style="width: 100%" :src="item.IMG" />
                </div>
                <div class="swiper-slide">
                  <img style="width: 100%" :src="item.USERIMG" />
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div class="mItemBottom">
              <img
                :src="item.heaPortrait.split('|')[1]"
                class="mItemBottomPic"
              />
              <div class="mItemBottomRight">
                <div class="mItemBottomName">
                  {{ item.name }}
                  <img
                    :src="item.heaPortrait.split('|')[0]"
                    class="mItemBottomNameIcon"
                  />
                </div>
                <div class="mItemBottomTime">{{ item.Time }}</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div v-if="listData.length == total">
        <div class="noMore">没有更多数据了~</div>
      </div>
    </div>

    <el-row class="rowBottom">
      <el-col :span="12">
        <div class="colHeadItem">
          <el-button
            @click="handlePrev"
            size="small"
            icon="el-icon-back"
            type="danger"
            >返回查单</el-button
          >
        </div>
      </el-col>
      <el-col :span="12">
        <div class="colHeadItem">
          <el-button
            @click="handleNext"
            size="small"
            icon="el-icon-more"
            type="primary"
            >加载更多</el-button
          >
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from "axios";
import { Loading, Message } from "element-ui";
import t from "../../assets/template.png";

// 节流
function _throttle(fn, delay = 50, that) {
  var lastTime, timer, delay;
  return function () {
    var _this = this;
    var args = arguments;
    var nowTime = Date.now();
    if (lastTime && nowTime - lastTime < delay) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(function () {
        lastTime = nowTime;
        fn.apply(_this, args);
      }, delay);
    } else {
      lastTime = nowTime;
      fn.apply(_this, args);
    }
  };
}

export default {
  data() {
    return {
      activeName: "a",
      total: 0,
      t,
      query: {
        act: "pic",
        id: "20220710124317250893",
        page: 1,
        m: "daochu",
      },
      listData: [],
      currentItem: "",
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.query.id = this.$route.query.id;
    }
    this.getData();
    this.$refs.box.addEventListener("scroll", _throttle(this.lazyLoading, 50));
  },
  methods: {
    // 滚动加载
    lazyLoading(e) {
      const scrollTop = e.target.scrollTop;
      const windowHeight = e.target.clientHeight;
      const scrollHeight = e.target.scrollHeight;
      console.log("this = ", this);
      // 滚动条到底部
      if (scrollTop + windowHeight >= scrollHeight - 20) {
        if (this.listData.length == this.total) {
          return;
        }
        this.query.page += 1;
        this.getData();
      }
    },
    handleTabClick() {},
    handlePrev() {
      this.$router.back();
      // if (this.query.page <= 1) {
      //   return;
      // }
      // this.query.page -= 1;
      // this.getData();
    },
    handleNext() {
      this.query.page += 1;
      this.getData();
    },
	  shuaxin() {
      this.query.page += 0;
      this.getData();
    },
    getData() {
      this.loading = Loading.service({
        fullscreen: true,
        text: "数据加载中...",
      });

      axios
        .get("http://222.211.75.228:5000/export.php", {
          params: this.query,
        })
        .then((res) => {
          console.log("res.data.data = ", res.data.data.length);
          if (res.data.data.length == 0) {
            this.loading.close();
            // Message({
            // type: "warning",
            // showClose: true,
            // message: "没有更多数据了！",
            //});
            return;
          }
          res.data.data.forEach((v) => {
            this.listData.push(v);
          });

          this.total = res.data.total;

          this.$nextTick(() => {
            new Swiper(".swiperBox", {
              autoplay: {
                disableOnInteraction: false,
                delay: 2500,
              },
              pagination: {
                el: ".swiper-pagination",
              },
            });
          });
          // Message({
          //   type: "success",
          //   showClose: true,
          //   message: "查询成功！",
          // });
          this.loading.close();
        })
        .catch(() => {
          this.loading.close();
        });
    },
    // 查看详情
    handleClick(item) {
      console.log("item = ", item);
      // item.danhao
    },
  },

  destroyed() {},
};
</script>

<style scoped>
@import url("./imgList.css");
</style>